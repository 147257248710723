export const mapAddressByCEP = (data) => {
    return {
        main: data['logradouro'],
        number: '',
        complement: '',
        reference: '',
        neighborhood: data['bairro'],
        cep: data['cep'],
        city: data['localidade'],
        state: data['uf'],
        type: 0,
        error: data['erro']
    }
}

export const mapAddressCreateDataDelivery = (data) => {
    return {
        "Cep": data['cep'],
        "Endereco": data['main'],
        "Localizacao": {
            "Latitude": data['latitude'],
            "Longitude": data['longitude']
        },
        "Numero": data['number'],
        "Complemento": data['complement'],
    }
}

export const mapAddressCreateDataPickup = (data) => {
    return {
        "Texto": "",
        "CidadeId": 0,
        "Localizacao": {
            "Latitude": data['latitude'],
            "Longitude": data['longitude']
        },
        "ModalidadeRetiradaId": 0,
        "AglomeracaoId": 0,
        "TotalRegistros": 0
    }
}