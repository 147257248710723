import { Box, Container, Grid } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux';
import CampanhaChaves from '../../assets/botao-giraffas-chaves.png'
import styled from 'styled-components';

export const Img = styled.img``;

function Cards(props) {
	const { feed, isMobile } = props;

	const openRoute = (index) => {
		if (index === 0) {
			window.open(`https://giraffasdelivery.voceqpad.com.br/`);
		} else if (index === 1) {
			window.location.href = `${window.location.href}promocoes`;
		} else if (index === 2) {
			window.location.href = `${window.location.href}cardapio`;
		}

	}

	return (
		<Container component="main" sx={{ pt: 4, pb: 4 }}>
			<Grid container spacing={2}>
				{feed && feed.map((item, index) => {
					const key = `card-image-${index}`

					return <Grid item xs={12} md={4} key={key}>
						<img style={{ cursor: "pointer" }} key={key} src={item.image} onClick={() => openRoute(index)} width="100%" alt="Imagem do card" />
					</Grid>
				})}

			</Grid>
			{/* {isMobile && <Box sx={{ paddingX: 2 }} onClick={() => {
				window.location.href = `${window.location.href}campanhachaves`;
			}}>
				<Img
					src={CampanhaChaves}
					className="our-history__our-history-image"
					alt="titulo session"
					width="100%"
					height='auto'
				/>
			</Box>} */}
		</Container>
	)
}

const mapStateToProps = (state) => {
	return {
		feed: state.ui.feed
	};
}

export default connect(
	mapStateToProps, {
})(Cards);
