import React from "react";
import { connect } from "react-redux";
import {
	CloseButton,
	CustomButton,
	CustomIcon,
	Div,
	DivMobile,
	H1,
	Img, WrapperImg, WrapperModal,
} from "./styles";
import { Container } from "@mui/system";
import { Box, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import BannerChaves from "../../assets/banner-chaves.png";
import BannerChavesMobile from "../../assets/banner-mobile.png";
import Antigos from "../../assets/antigos.png";
import Novos from "../../assets/novos.png";
import Lojas from "../../assets/lojasParticipantes.png";
import Regras from "../../assets/regras.png";
import QrCodeChaves from "../../assets/qrcode_chaves.png";

import { useState } from "react";
import { Icon } from "../Common";


const StoresData = [
	// 'DF - SHOPPING - SHOPPING CNB',
	// 'DF - RUA - PARANOA',
	// 'DF - RUA - PLANALTINA',
	// 'DF - RUA - SAMAMBAIA',
	// 'DF - RUA - GUARA I',
	// 'DF - RUA - HELIO PRATES',
	'AL - SHOPPING - ARAPIRACA GARDEN SHOPPING',
	// 'BA - SHOPPING - SHOPPING CONQUISTA SUL',
	// 'CE - SHOPPING - SHOPPING RIOMAR FORTALEZA',
	// 'MA - SHOPPING - SHOPPING DA ILHA',
	// 'MG - RUA - PARACATU',
	'ES - SHOPPING - SHOPPING VILA VELHA',
	// 'MT - SHOPPING - SHOPPING PANTANAL SHOPPING',
	// 'MT - SHOPPING - SHOPPING ESTACAO CUIABA',
	// 'GO - SHOPPING - SHOPPING BURITIS',
	// 'GO - RUA - VALPARAIZO POSTO ANTARES',
	// 'PR - SHOPPING - COLOMBO PARK SHOP',
	// 'PB - SHOPPING - SHOPPING MANGABEIRA',
	// 'PA - SHOPPING - SHOPPING BOSQUE GRAO PARA',
	// 'PE - SHOPPING - SHOPPING RECIFE',
	// 'TO - SHOPPING - SHOPPING CAPIM DOURADO',
	// 'RJ - SHOPPING - PARK SHOPPING CAMPO GRANDE',
	'SC - SHOPPING - BALNEARIO CAMBORIU',
	// 'SP - SHOPPING - SHOPPING LITORAL PLAZA',
	// 'SP - SHOPPING - SHOPPING GUARULHOS',
	// 'SP - SHOPPING - SHOPPING JARAGUA ARARAQUARA',
	// 'SP - SHOPPING - NOVO SHOPPING RIBEIRAO',
	// 'SP - SHOPPING - SHOPPING ARICANDUVA',
	// 'SP - SHOPPING - CANTAREIRA NORTE SHOPPING',
	// 'SP - SHOPPING - SHOPPING CENTER VALE',
	// 'SP - SHOPPING - SHOPPING BONSUCESSO',
	// 'SP - SHOPPING - SHOPPING CIDADE SAO PAULO',
	// 'SP - SHOPPING - SHOPPING CENTER III'
]

const Campaign = (props) => {
	const { isMobile } = props;
	const [open, setOpen] = useState(false);
	const [openStores, setOpenStores] = useState(false);
	const [openRules, setOpenRules] = useState(false);

	const handleClose = () => {
		setOpen(false)
	}

	const handleCloseRules = () => {
		setOpenRules(false)
	}

	const handleCloseStores = () => {
		setOpenStores(false)
	}
	return (
		<>
			<Container
				disableGutters
				component="main"
				sx={{ pt: 10, pb: 2, pl: 2, pr: 2 }}
			>
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					<Img
						src={BannerChaves}
						className="our-history__our-history-image"
						alt="titulo session"
						width="100%"
						height="100%"
					/>
				</Box>
				<Box sx={!isMobile ? { paddingX: 10, marginY: 5 } : { marginY: 5 }}>
					<Typography
						sx={{ fontFamily: "Graviola Soft", textAlign: "center" }}
					>
						Isso! Isso! Isso!
					</Typography>
					<Typography
						sx={{ fontFamily: "Graviola Soft", textAlign: "center" }}
					>
						A turma do Chaves está de volta e trouxe novos personagens! Essa é a sua chance de levar os bonecos da vila mais amada do Brasil para casa.
					</Typography>
				</Box>
				{isMobile && <>
					<DivMobile onClick={() => setOpenRules(true)}>
						<CustomIcon>
							<Icon width={"25px"} height={"25px"} name={"rules"} stroke={"primary"} />
						</CustomIcon>
						<H1>Confira as regras para resgate</H1>
					</DivMobile>
					<DivMobile onClick={() => setOpenStores(true)}>
						<CustomIcon>
							<Icon width={"25px"} height={"25px"} name={"address"} stroke={"primary"} />
						</CustomIcon>
						<H1>Unidades Participantes</H1>
					</DivMobile>
				</>
				}
				<Grid container spacing={2} sx={isMobile ? { mt: 2 } : {}}>
					<Grid item xs={12} lg={6}>
						<Box sx={{ height: '220px', border: '2px solid #ED8B00', borderRadius: '10px', padding: 2 }}>
							<Grid container spacing={2}>
								<Grid item xs={6} lg={7} sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
									<Typography
										variant={isMobile ? 'h6' : "h5"}
										sx={{ fontFamily: "Graviola Soft", marginBottom: "10px" }}
									>
										Coleção Atual
									</Typography>
									<Typography
										sx={{ fontSize: isMobile ? '12px' : '14px', marginBottom: "20px" }}
									>
										{isMobile ? 'Chegaram os novos personagens da vila mais amada do Brasil.' :
											'Confira agora mesmo no App Giraffas os novos personagens da vila mais amada do Brasil.'}
									</Typography>
									<Box sx={{ background: "#ED8B00", borderRadius: '10px', paddingY: 1, paddingX: 2, cursor: 'pointer' }} onClick={() => {
										if (isMobile) {
											window.location.href = 'https://voceqpad.com.br/app/giraffas'
											return
										}

										setOpen(true)
									}}>
										<Typography
											sx={{
												fontFamily: "Graviola Soft", color: 'white', textTransform: "uppercase", fontWeight: 'bold',
												fontSize: isMobile ? '14px' : '20px'
											}}
										>
											Peça Já
										</Typography>
										<Typography
											sx={{
												fontFamily: "Graviola Soft", color: 'white', textTransform: "uppercase", fontWeight: 'bold', fontSize: isMobile ? '11px' : '16px'
											}}
										>
											Na loja ou delivery
										</Typography>
									</Box>
								</Grid>
								<Grid item xs={6} lg={5} sx={{ display: 'flex', justifyContent: 'center' }}>
									<Img
										src={Novos}
										className="our-history__our-history-image"
										alt="titulo session"
										width="auto"
										height={isMobile ? '180px' : 'auto'}
									/>
								</Grid>
							</Grid>
						</Box>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Box sx={{ height: '220px', border: '2px solid #772583', borderRadius: '10px', padding: 2, cursor: 'pointer' }} onClick={() => {
							if (isMobile) {
								window.location.href = 'https://www.voceqpad.com.br/app/giraffas'
								return
							}

							setOpen(true)
						}}>
							<Grid container spacing={2}>
								<Grid item xs={6} lg={7} sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
									<Typography
										variant={isMobile ? 'h6' : "h5"}
										sx={{ fontFamily: "Graviola Soft", marginBottom: "10px" }}
									>
										Coleção Anterior
									</Typography>
									<Typography
										sx={{ fontSize: isMobile ? '12px' : '14px', marginBottom: "8px" }}
									>
										Faltou algum aí? Não se preocupe! Garanta os personagens da primeira campanha aqui.
									</Typography>
									<Box sx={{ background: "#772583", borderRadius: '10px', paddingY: 1, paddingX: 2, cursor: 'pointer' }}>
										<Typography
											sx={{
												fontFamily: "Graviola Soft", color: 'white', textTransform: "uppercase",
												fontWeight: 'bold', fontSize: isMobile ? '14px' : '20px'
											}}
										>
											Peça Já
										</Typography>
										<Typography
											sx={{
												fontFamily: "Graviola Soft", color: 'white', textTransform: "uppercase", fontWeight: 'bold', fontSize: isMobile ? '11px' : '16px'
											}}
										>
											Somente no App
										</Typography>
									</Box>
								</Grid>
								<Grid item xs={6} lg={5} sx={{ display: 'flex', justifyContent: 'center' }}>
									<Img
										src={Antigos}
										className="our-history__our-history-image"
										alt="titulo session"
										width="auto"
										height={isMobile ? '180px' : 'auto'}
									/>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>
				<Box sx={!isMobile ? { paddingX: 10, marginY: 5 } : { marginY: 5 }}>
					<Typography
						sx={{ fontFamily: "Graviola Soft", textAlign: "center" }}
					>
						A compra da Coleção Anterior está disponível apenas via App Giraffas e em lojas selecionadas.
						Confira a unidade mais próxima de você e saiba mais sobre as regras de resgate.
					</Typography>
				</Box>
				{!isMobile && <Grid container spacing={2} sx={{ mb: 8 }}>
					<Grid item xs={12} lg={6} sx={{ display: 'flex', justifyContent: 'end', cursor: 'pointer' }} onClick={() => setOpenStores(true)}>
						<Img
							src={Lojas}
							className="our-history__our-history-image"
							alt="titulo session"
							width="auto"
							height="auto"
						/>
					</Grid>
					<Grid item xs={12} lg={6} sx={{ cursor: 'pointer' }} onClick={() => setOpenRules(true)}>
						<Img
							src={Regras}
							className="our-history__our-history-image"
							alt="titulo session"
							width="auto"
							height="auto"
						/>
					</Grid>
				</Grid>}
			</Container>
			<Dialog
				onClose={handleClose}
				open={open}
				PaperProps={{
					elevation: 0,
					style: {
						overflowY: 'unset',
						maxWidth: '22rem',
						minWidth: '22rem'
					}
				}}
			>
				<Box sx={{ padding: '1rem 2rem' }}>
					<WrapperModal>
						<CloseButton className='close-button' onClick={() => {
							handleClose()
						}}>
							<CustomIcon>
								<Icon width={"25px"} height={"25px"} name={"close"} stroke={"lightGrey"} />
							</CustomIcon>
						</CloseButton>
						<H1>Leia o QRCode e resgate no app</H1>
						<WrapperImg>
							<Img src={QrCodeChaves} width='100%' height='100%' alt="qr-code" />
						</WrapperImg>
						<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
							<CustomButton onClick={() => handleClose()}>
								Continuar
							</CustomButton>
						</Box>
					</WrapperModal>
				</Box>

			</Dialog>
			<Dialog
				onClose={handleCloseStores}
				open={openStores}
				scroll='paper'
				PaperProps={{
					elevation: 0,
				}}
			>
				<DialogContent dividers>
					<WrapperModal>
						<Div>
							<CustomIcon>
								<Icon width={"25px"} height={"25px"} name={"address"} stroke={"primary"} />
							</CustomIcon>
							<H1>Unidades Participantes</H1>
						</Div>
						<WrapperImg>
							<Box sx={{ border: '1px solid #C1C1C1', padding: 1, borderRadius: '10px' }}>
								{StoresData && StoresData.map((item) => {
									return <Typography sx={{ fontFamily: 'Graviola Soft', fontSize: '14px', textTransform: 'unset', marginBottom: 1 }}>{item}</Typography>
								})}
							</Box>
						</WrapperImg>
						<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
							<CustomButton onClick={() => handleCloseStores()}>
								Continuar
							</CustomButton>
						</Box>
					</WrapperModal>
				</DialogContent>
			</Dialog>
			<Dialog
				onClose={handleCloseRules}
				open={openRules}
				scroll='paper'
				PaperProps={{
					elevation: 0,
				}}
			>
				<DialogContent dividers>
					<WrapperModal>
						<Div>
							<CustomIcon>
								<Icon width={"25px"} height={"25px"} name={"rules"} stroke={"primary"} />
							</CustomIcon>
							<H1>Confira as regras para resgate</H1>
						</Div>
						<Typography sx={{ fontFamily: 'Graviola Soft' }}>Lojas participantes</Typography>
						<Typography sx={{ fontFamily: 'Graviola Soft' }}>Os brindes poderão ser adquiridos apenas no APP Giraffas na modalidade Delivery ou Retire no Balcão, de acordo com a disponibilidade da loja. </Typography>
						<Typography sx={{ fontFamily: 'Graviola Soft', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
							handleCloseRules()
							setOpenStores(true)
						}}>
							Confira aqui a unidade mais próxima de você!
						</Typography>

						<Typography sx={{ fontFamily: 'Graviola Soft', mt: 5 }}>Pedido mínimo</Typography>
						<Typography sx={{ fontFamily: 'Graviola Soft' }}>Para adquirir o brinde, é necessário que o valor total do seu pedido seja de no mínimo R$ 25,00, não incluso o frete.</Typography>
						<Typography sx={{ fontFamily: 'Graviola Soft' }}>Só é possível adquirir um brinde por pedido.</Typography>
						<Typography sx={{ fontFamily: 'Graviola Soft' }}>O valor do Brinde pode variar de acordo com a região.</Typography>

						<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
							<CustomButton onClick={() => handleCloseRules()}>
								Continuar
							</CustomButton>
						</Box>
					</WrapperModal>
				</DialogContent>
			</Dialog>
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, {})(Campaign);
