import styled from 'styled-components'

export const Wrapper = styled.div`
    margin-top: 4rem;
`

export const SessionTitle = styled.div`
	font-size: 2.5rem;
	width: 100%;
	text-align: center;
	color: ${props => props.theme.colors.purple};
	font-weight: bold;
`
export const Img = styled.img``

export const SessionSubTitle = styled.div`
	font-size: 2rem;
	width: 100%;
	text-align: center;
	color: ${props => props.theme.colors.titleSession};
	font-weight: 400;
`
export const SessionSubTitleHigh = styled.div`
	font-size: 3.5rem;
	width: 100%;
	text-align: center;
	color: ${props => props.theme.colors.titleSession};
	font-weight: 400;
	padding: 0 12rem;
	line-height: 1.2;
	
	@media (max-width: 756px){
		font-size: 1.6rem;
		padding: 0 2rem;
	}
`
export const Title = styled.div`
	font-size: 1.6rem;
	width: 100%;
	color: ${props => props.theme.colors.titleSession};
	font-weight: bold;
`
export const Paragraph = styled.p`
	font-size: 13px;
`
export const SecondarySessionParagraph = styled.p`
	font-size: 13px;
	padding: 0 1rem;
`
export const PrimarySession = styled.div`
	font-size: 1.6rem;
	width: 100%;
	color: ${props => props.theme.colors.titleSession};
	font-weight: bold;
	display: flex;
	flex-direction: row;
	align-items: center;
`
export const SecondarySession = styled.div`
	width: 100%;
	color: ${props => props.theme.colors.purple};
	display: flex;
	flex-direction: row;
	align-items: center;
`
export const PrimarySessionTitle = styled.div`
	font-size: 16px;
	width: 65%;
	color: ${props => props.theme.colors.primary};
	font-weight: bold;
	padding: 0 1rem;
`
export const H6 = styled.div`
    color: ${props => props.theme.colors.titleSession};
    font-size: 22px;
    font-weight: 500;
    text-align: center;
`

export const OurHistorySection = styled.section`
  display: flex;
  flex-direction: column;
	overflow: hidden;
  width: 100%;
	max-width: 75rem;
	margin: 0 auto;
	padding: 2rem 0 3rem 0;
`

OurHistorySection.Header = styled.div`
	display: flex;
  row-gap: 1rem;
	display: flex;
  flex-direction: column;
  align-items: center;
	padding: 1rem 1rem 2rem 1rem;

	.our-history__logo-image {
		height: 5rem;
    width: 5rem;
	}

	.our-history__our-history-image {
		width: 80%;
	}

	@media (min-width: 768px) {
		flex-direction: row;
		align-items: flex-start;
		column-gap: 1rem;
		row-gap: 0rem;
		padding: 1rem 0 2rem 0;

		.our-history__our-history-image {
			width: 25rem;
   	 	align-self: flex-start;
		}
	}
`

OurHistorySection.Content = styled.div`
	display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
	row-gap: 1.5rem;

	img {
    max-width: 100%;
	}

	.our-history__how-image {
    align-self: flex-start;
	}

	.our-history__where-image {
    align-self: flex-end;
	}

	.our-history__when-image {
    align-self: flex-start;
	}

	@media (min-width: 768px) {
		.our-history__how-image, .our-history__where-image,
		.our-history__when-image {
			max-width: 50%;
    	width: calc(50% - 2rem);
		}

		.our-history__curiosities {
    	width: 100%;
			max-width: 70%;
		}
	}
`

OurHistorySection.Curiosities = styled.div`
	display: flex;
  flex-direction: column;
	margin: 2.5rem 0 0 0;

	.our-history__curiosities-panel-image {
    align-self: center;
		width: 100%;
	}

	.our-history__curiosities-text-image {
    align-self: center;
    max-width: 5rem;
		margin: 0.5rem 20% 0 auto;
	}

	@media (min-width: 768px) {
		.our-history__curiosities-text-image {
    	max-width: 7rem;
		}
	}
`
