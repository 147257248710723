import styled from "styled-components";

export const Img = styled.img``;

export const WrapperModal = styled.div`
	> div {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		> h1 {
			color: ${(props) => props.theme.colors.primary};
			font-size: 18px;
			font-weight: 400;
			text-align: center;
		}
	}

	> h1 {
		color: ${(props) => props.theme.colors.primary};
		font-size: 18px;
		font-weight: 400;
		text-align: center;
	}
`;

export const DivMobile = styled.div`
	padding: .2rem 2rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	> h1 {
		color: ${(props) => props.theme.colors.primary};
		font-size: 18px;
		font-weight: 400;
		text-align: center;
	}
`;

export const Div = styled.div``;

export const CloseButton = styled.button`
	padding: 1rem;
	background: none;
	border: 0 none;
	color: ${(props) =>
		(props.theme &&
			props.theme.main &&
			props.theme.main.general &&
			props.theme.main.general.textPrimaryColor) ||
		"#000"};
	text-align: end;
	position: absolute;
	right: 0;
	top: 0;
	font-size: 1.5rem;
	z-index: 1;
	cursor: pointer;

	@media (min-width: 40rem) {
		right: -1rem;
		top: -3.5rem;
	}
`;
export const CustomIcon = styled.div`
	padding: 0 0.6rem;
	line-height: 1.75;
`;
export const WrapperImg = styled.div``;

export const CustomButton = styled.button`
	color: #fff;
	padding: 8px 14px;
	border-radius: 4px;
	font-weight: 100;
	outline: 0;
	border: 0;
	margin: 0.5rem 0 0 0;
	text-align: center;
	font-size: 0.975rem;
	line-height: 2;
	letter-spacing: 0.02857em;
	texttransform: "unset";
	font-family: Graviola Soft;
	min-width: 64px;
	background-color: ${(props) => props.theme.colors.primary};
	cursor: pointer;

	&:hover {
		background-color: ${(props) => props.theme.colors.buttonHover};
	}
`;
export const H1 = styled.h1``;
