import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import { connect } from "react-redux";
import { fetchMenuStore } from "../redux/actions/menu";
import { fetchGroupsHighlights } from "../redux/actions/ui";
import { useDeviceLayout } from "../components/utilities/useCustomLayout";
import styled from "styled-components";
import { Navbar } from "../components/Navbar";
import Campaign from "../components/Campaign";
import Footer from "../components/Footer";

const WrapperBackground = styled.div`
	background-color: ${(props) => props.theme.colors.backgroundMain};
`;

const CampaignPage = (props) => {
	const {
		fetchMenuStore,
		fetchGroupsHighlights,
		setScrollStore,
		...propsAuth
	} = props;

	const isMobile = useDeviceLayout({
		isMobile: true,
	});

	return (
		<>
			<React.Fragment>
				<GlobalStyles
					styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
				/>
				<CssBaseline />
				<Navbar {...propsAuth} setScrollStore={setScrollStore} />
				<WrapperBackground>
					<Campaign isMobile={isMobile} />
				</WrapperBackground>
				<Footer />
			</React.Fragment>
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, {
	fetchMenuStore,
	fetchGroupsHighlights,
})(CampaignPage);
